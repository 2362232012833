<template>
  <div class="row justify-content-start px-8 px-lg-10">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ $t("commons.date") }}</th>
              <th scope="col">{{ $t("commons.operation") }}</th>
              <th scope="col">
                {{ $t("commons.price") }}
              </th>
              <th scope="col">{{ $t("commons.quantity") }}</th>
              <th scope="col">{{ $t("commons.amount") }}</th>
              <th scope="col">{{ $t("finance.fee") }}</th>
              <th scope="col">{{ $t("commons.status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="historyData.length === 0">
              <td colspan="7" class="text-center">
                {{ $t("commons.no_data_available") }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in historyData"
              :key="'wth-history-' + index"
            >
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.created_at | dateFormat }}</td>
              <td>
                <span
                  class="badge"
                  :class="[(item.type_code == 'quicksale-buy') ? 'badge-success' : 'badge-danger']"
                >
                  {{ item.type }}
                </span>
              </td>
              <td>{{ item.price_formatted }}</td>
              <td>{{ item.amount_formatted }}</td>
              <td>{{ item.total_formatted }}</td>
              <td>{{ item.fee_formatted }}</td>
              <td>
                {{ item.status_str }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="col-12 row col-sm-12 justify-content-center mt-2"
          v-if="canLoadMore"
        >
          <form-submit-btn
            :btnClass="'btn btn-primary w-25'"
            @click="loadMore"
            :text="$t('commons.load_more')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from "@/config/_date-format";
import { mapState, mapActions } from "vuex";
export default {
  name: "QuicksaleHistory",
  data() {
    return {};
  },
  filters: {
    dateFormat,
  },
  computed: {
    ...mapState({
      historyLoading: (state) => state.quicksale.historyLoading,
      historyData: (state) => state.quicksale.historyData,
      historyLinks: (state) => state.quicksale.historyLinks,
      historyMeta: (state) => state.quicksale.historyMeta,
      quicksale: state => state.quicksale.quicksale,
    }),
    canLoadMore() {
      return this.historyLinks.next && this.historyLinks.next !== null
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions("quicksale", ["GET_QUICKSALE_HISTORY"]),
    loadMore() {
      if (this.historyMeta?.current_page && this.canLoadMore) {
        this.GET_QUICKSALE_HISTORY({
          currencyCode: this.quicksale.crypto.code.toLowerCase(),
          page: this.historyMeta.current_page + 1,
        });
      }
    },
  },
};
</script>
