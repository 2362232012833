<template>
  <div class="modal fade mt-17" :class="{ show: modalData.isVisible }">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalData.title }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close font-size-h5 text-dark"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="px-10">
            <div class="d-flex flex-wrap justify-content-between">
              <span>{{ $t("commons.quantity") }}</span>
              <span class="font-weight-bolder">{{ modalData.quantity }} </span>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
              <span>{{ $t("commons.amount") }}</span>
              <span class="font-weight-bolder">{{ modalData.amount }} </span>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
              <span>{{ $t("commons.commission") }}</span>
              <span class="font-weight-bolder">{{ modalData.commission }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Modal",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      modalData: (state) => state.quicksale.quickSaleTransactionSummaryModal,
    }),
  },
  methods: {
    closeModal() {
      this.$store.commit("quicksale/SET_QUICKSALE_TS_MODAL", {
        isVisible: false,
        title: "",
        quantity: "",
        amount: "",
        commission: "",
      });
    },
  },
};
</script>

<style scoped>
.show {
  display: block;
}
</style>
