<template>
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form mt-10">
          <!--begin::Step 1-->
          <div
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label>{{ $t("quicksale.crypto_type") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('quicksale.crypto_type')"
                    v-slot="{ classes, errors }"
                  >
                    <multiselect
                      v-model="quicksale.crypto"
                      @input="getCoinSelection"
                      :options="getFilteredCrypto"
                      :internal-search="true"
                      :loading="cryptosLoading"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('quicksale.crypto_type')"
                      :class="classes"
                      :disabled="isSubmitting"
                    >
                      <template slot="singleLabel" slot-scope="{ option }" >{{ option.name }}</template>
                      <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="noOptions">{{ "List is empty" }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label>{{ $t("commons.currency") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('commons.currency')"
                    v-slot="{ classes, errors }"
                  >
                    <multiselect
                      v-model="quicksale.currency"
                      @input="getCoinSelection"
                      :options="getFilteredCurrency"
                      :internal-search="true"
                      :loading="cryptosLoading"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('commons.currency')"
                      :class="classes"
                      :disabled="isSubmitting"
                    >
                      <template slot="singleLabel" slot-scope="{ option }" >{{ option.name }}</template>
                      <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="noOptions">{{ "List is empty" }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group row d-flex">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label class="col-form-label">{{ $t('finance.quantity') }}</label>
                    <validation-provider
                      rules="required"
                      :name="$t('finance.quantity')"
                      v-slot="{ classes, errors }"
                    >
                      <currency-input
                        class="form-control"
                        :placeholder="$t('finance.quantity')"
                        :class="classes"
                        v-model="quicksale.amount"
                        :precision="8"
                        :disabled="
                          quantityAmountDisabled() || isSubmitting
                        "
                        @keyup="getCalculate('amount')"
                        @blur="clearCalculateForm"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label class="col-form-label">&nbsp;</label>
                    <validation-provider
                      rules="required"
                      :name="$t('finance.amount')"
                      v-slot="{ classes, errors }"
                    >
                      <currency-input
                        class="form-control"
                        :placeholder="$t('finance.amount')"
                        :class="classes"
                        :precision="quicksaleCurrenyIsCrypto"
                        v-model="quicksale.total"
                        :disabled="
                          quantityAmountDisabled() || isSubmitting
                        "
                        @keyup="getCalculate('total')"
                        @blur="clearCalculateForm"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex flex-wrap justify-content-between">
              <span>{{ $t("finance.available_limit") }}</span>
              <span v-if="!balanceLoading">{{ balanceByCurrency }} </span>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
              <span>{{ $t("quicksale.sell_price") }}</span>
              <span>{{ priceSellFormatted }} </span>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-center pt-10">
            <form-submit-btn
              :btnClass="'btn btn-pill btn-danger w-75'"
              :isSubmitting="isSubmitting"
              @click="handleSubmit(_handleSubmit)"
              :text="$t('commons.approve')"
            />
          </div>
          <!--end::Actions-->
        </form>
      </ValidationObserver>
    </div>
</template>


<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "Quicksale",
  data() {
    return {
      isSubmitting: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      cryptosLoading: (state) => state.quicksale.currenciesLoading,
      balanceLoading: (state) => state.quicksale.balancesLoading,
      balanceByCurrency: (state) => state.quicksale.balanceByCurrency,
      priceBuyFormatted: (state) =>
        state.quicksale.quickSaleBuySellPrices.priceBuyFormatted,
      priceSellFormatted: (state) =>
        state.quicksale.quickSaleBuySellPrices.priceSellFormatted,
      quicksale: state => state.quicksale.quicksale,
      currency: (state) => state.global.currency,
      crypto: (state) => state.global.crypto,
    }),
    ...mapGetters('global', ['getCrypto', 'getCurrency']),
    getFilteredCrypto: function () {
      const vm = this;
      return this.getCrypto.filter(function (item) {
        return item.id != vm?.quicksale?.currency?.id;
      });
    },
    getFilteredCurrency: function () {
      const vm = this;
      return this.getCurrency.filter(function (item) {
        return item.id != vm?.quicksale?.crypto?.id && item.quicksale === true;
      });
    },
    quicksaleCurrenyIsCrypto() {
      return this.quicksale?.currency?.crypto ? 8 : 2;
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions("quicksale", [
      "GET_BALANCES_BY_CURRENCY",
      "GET_QUICKSALE_BUY_SELL_PRICES",
      "POST_SELL",
      "POST_CALCULATE",
      "GET_QUICKSALE_HISTORY"
    ]),
    getCalculate(calcType) {
      if (this.quicksale.amount === null && this.quicksale.total === null) {
        return;
      } else if (calcType === 'total' && this.quicksale.total === null) {
        this.quicksale.amount = null;
        return;
      } else if (calcType === 'amount' && this.quicksale.amount === null) {
        this.quicksale.total = null;
        return;
      }
      this.POST_CALCULATE({ buy: 20, calcType });
    },
    clearCalculateForm() {
      this.$refs.form.reset();
    },
    quantityAmountDisabled() {
      return (
        (!this.quicksale.crypto && !this.quicksale.currency) || this.isSubmitting
      );
    },
    getCoinSelection() {
      this.$store.commit('quicksale/CLEAR_FORM');
      this.GET_BALANCES_BY_CURRENCY();
      this.GET_QUICKSALE_BUY_SELL_PRICES();
      this.GET_QUICKSALE_HISTORY({
        currencyCode: this.quicksale.crypto.code.toLowerCase(),
        page: 1,
      });
      this.$refs.form.reset();
    },
    _handleSubmit() {
      const formData = {
        currency: this.quicksale.crypto.code,
        base_currency: this.quicksale.currency.code,
        amount: this.quicksale.amount.toString(),
      };
      this.isSubmitting = true;
      this.POST_SELL({ formData })
        .then(() => {
          this.getCoinSelection();
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  created() {
    this.GET_CURRENCY();
  },
};
</script>
